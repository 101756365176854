@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '@core/scss/angular/libs/datatables.component.scss';


.datatable-scroll {
    width: auto !important;
  }


.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 28px!important;
    align-items: center;
  }
  .ng-select.ng-select-single .ng-select-container {
  height: 28px!important;
  }
  #aa ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    border-bottom: 1px solid;
    padding: 0;
    z-index: 1;
  }
  #aa .ng-dropdown-panel .ng-dropdown-header {
    padding: 0;
  }
  #aa .ng-dropdown-panel .scroll-host,#aa .ng-dropdown-panel .ng-dropdown-header {
    overflow: scroll;
    width: 800px;
  }
  #aa .ng-dropdown-panel.ng-select-top,#aa .ng-dropdown-panel{
    // overflow: scroll;
    left: -110%;
      z-index: 1050;
  }
  .validation-text{
    font-size: 0.8rem;
    margin-top: .5rem!important;
  }
  .swal2-title{
    font-size: 17px!important;
  }